<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="goods_name" label="商品名称">
              <a-input allowClear
                       v-model="searchForm.goods_name"
                       placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="sku_number"
                               label="SKU码">
              <a-input allowClear
                       v-model="searchForm.sku_number"
                       placeholder="SKU码"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" class="flex justify-between items-center">
            <a-form-model-item prop="goods_create_time" style="flex:1;"
                               label="创建日期">
              <a-range-picker @change="timeChange"
                              v-model="searchForm.goods_create_time"
                              valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
            <div>
              <a-button @click="initData" type="primary" html-type="submit">搜索</a-button>
              <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
            </div>
              
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table ref="tableRef"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :row-selection="rowSelection"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">商品库列表</h4>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center">
            <a-tabs type="card"
                    class="w-full"
                    @change="handlerTypeChange"
                    :activeKey="activeKey">
              <a-tab-pane v-for="item in statusTypeList"
                          :key="item.id"
                          :tab="item.name"></a-tab-pane>
            </a-tabs>
            <a-button type="link" class="p-0 ml-6" 
                @click="handlerExport">导出全部商品</a-button>
          </div>
          <div class="flex justify-between">
            <a-button type="primary"
                      :disabled="!rowSelection.selectedRowKeys.length"
                      @click="handlerAddShop"
                      class="ml-3">发布到小程序/网站在售</a-button>
            <!-- <a-button
              type="primary"
              :disabled="!rowSelection.selectedRowKeys.length"
              @click="handlerAddMall"
              class="ml-3"
              >发布到商城</a-button
            > -->
            <a-button type="primary"
                      @click="handlerAdd"
                      class="ml-3">新增</a-button>
          </div>
        </div>
      </template>
      <template #operation="{ record }">
        <template v-if="searchForm.status!=-1">
          <a-button type="link" style="padding:0;" class="mr-3"
                    @click="handlerEdit(record.goods_id)">编辑</a-button>
          <a-button type="link" style="padding:0;"
                    @click="handlerStatus(record.goods_id, -1)">禁用</a-button>
        </template>
        <a-button v-else type="link" style="padding:0;"
                  @click="handlerStatus(record.goods_id, 1)">恢复</a-button>
      </template>
    </base-table>
    <type-shop-modal :goodsList="rowSelection.selectedRowKeys"
                     v-if="isShopAdd"
                     :show.sync="isShopAdd" />
    <type-mall-modal :goodsList="rowSelection.selectedRowKeys"
                     v-if="isMallAdd"
                     :show.sync="isMallAdd" />
  </div>
</template>

<script>
import { getGoodsList, updateGoodsStatus, exportGoods } from "@/api/goods";
import pageData from "./columns";
import TypeShopModal from "./components/type-shop-modal.vue";
import TypeMallModal from "./components/type-mall-modal.vue";
import { goodsStatusList } from "@/utils/type";
export default {
  components: { TypeShopModal, TypeMallModal },
  data () {
    return {
      ...pageData,
      statusTypeList: goodsStatusList,
      activeKey: 0,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      total: 0,
      tableData: [],
      isShopAdd: false,
      isMallAdd: false,
      searchForm: {
        sale_status: "1",
        sale_location: "",
        goods_number: "",
        goods_name: "",
        sku_number: "",
        type: "",
        add_time_start: "",
        add_time_end: "",
        page: 1,
        page_count: 20,
        goods_create_time: [],
        status: ""
      },
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id);
          } else {
            this.rowSelection.selectedRowKeys =
              this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    };
  },
  mounted () {
    this.initData();
  },
  methods: {
    async initData () {
      const { data, code } = await getGoodsList(this.searchForm);
      if (code === 0) {
        this.tableData = data.list;
        this.total = data.total_count
      }
    },
    resetForm () {
      this.$refs.searchRef.resetFields();
      this.searchForm.add_time_end = "";
      this.searchForm.add_time_start = "";
      this.initData();
    },
    handlerAdd () {
      const newPage = this.$router.resolve({
        path: "/goods-edit",
        query: { type: "add" },
      });
      window.open(newPage.href, "_blank");
    },

    // 快捷添加到 门店某分类
    handlerAddShop() {
      if (this.rowSelection.selectedRowKeys.length === 0) {
        this.$message.warning("请先勾选要发布的商品");
        return;
      }
      this.isShopAdd = true;
    },
    // 快捷添加到 商城某分类
    // handlerAddMall() {
    //   if (this.rowSelection.selectedRowKeys.length === 0) {
    //     this.$message.warning("请先勾选要发布的商品");
    //     return;
    //   }
    //   this.isMallAdd = true;
    // },

    handlerEdit (id) {
      const newPage = this.$router.resolve({
        path: "/goods-edit",
        query: { type: "edit", id: id },
      });
      window.open(newPage.href, "_blank");
    },
    async handlerStatus(id, status) {
      const { code } = await updateGoodsStatus({
        goods_id: id,
        status: status
      });
      if (code === 0) {
        this.initData();
        if(status == -1){
          this.$message.success("已放入回收站");
        }else{
          this.$message.success("已恢复");
        }
      }
    },

    timeChange(e) {
      this.searchForm.add_time_start = e[0];
      this.searchForm.add_time_end = e[1];
    },

    handlerTypeChange (e) {
      this.activeKey = e;

      if (e == 0) {
        this.searchForm.status = 1;
        this.searchForm.sale_status = 1;
      } else if (e == 1) {
        this.searchForm.status = 1;
        this.searchForm.sale_status = 2;
      } else if(e == 2) {
        this.searchForm.status = -1;
        this.searchForm.sale_status = "";
      } else {
        this.searchForm.status = "";
        this.searchForm.sale_status = "";
      }
      this.searchForm.page = 1;
      this.initData();
    },

    handlerExport(){
      exportGoods()
    },
  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
