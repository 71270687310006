// import { formatDate } from '@/utils/index.js'
// import { message } from 'ant-design-vue'
export const rowKey = 'goods_id'
export const columns = [ // 角色列表
  // {
  //   title: '商品编码',
  //   dataIndex: 'goods_number',
  //   align: 'center'
  // },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center'
  },
  // {
  //   title: '商品分类',
  //   dataIndex: 'type',
  //   align: 'center',
  //   width: "9%",
  //   slots: {
  //     customRender: 'type'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => formatType(val)
  // },
  {
    title: '小程序/网站在售',
    dataIndex: 'is_on_sale_shop',
    align: 'center',
    width: "9%",
    slots: {
      customRender: 'is_on_sale_shop'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"是":""}
  },
  // {
  //   title: '商城在售',
  //   dataIndex: 'is_on_sale_mall',
  //   align: 'center',
  //   width: "9%",
  //   slots: {
  //     customRender: 'is_on_sale_mall'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"是":""}
  // },
  {
    title: '创建日期',
    dataIndex: 'add_time',
    align: 'center',
    width: "13%",
    // slots: {
    //   customRender: 'add_time'
    // },
    // slotsType: 'format',
    // slotsFunc: (val) => formatDate(val)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'operation'
    }
  },
]

// 基本SKU列数据
export const skuColumns = [
  {
    title: '口味',
    dataIndex: 'flavor_name',
    align: 'center',
    slots: {
      customRender: 'flavor_name'
    },
  },
  {
    title: '尺寸',
    dataIndex: 'specification_name',
    align: 'center',
    slots: {
      customRender: 'specification_name'
    },
  },
  {
    title: 'SKU码',
    dataIndex: 'sku_number',
    align: 'center',
    slots: {
      customRender: 'sku_number'
    },
  },
  {
    title: '默认价',
    dataIndex: 'price',
    width:120,
    align: 'center',
    slots: {
      customRender: 'price'
    },
  },
  {
    title: '区域价',
    dataIndex: 'area_price',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'area_price'
    }
  },
  {
    title: '售卖',
    dataIndex: 'status',
    align: 'center',
    width:70,
    slots: {
      customRender: 'status'
    }
  },
  {
    title: 'SKU图片\n724*340',
    dataIndex: 'horizontal_image_path',
    align: 'center',
    width: 230,
    slots: {
      customRender: 'horizontal_image_path'
    }
  }]

// 蛋糕SKU列数据
export const skuCakeColumns = [
  {
    title: '口味',
    dataIndex: 'flavor_name',
    align: 'center',
    slots: {
      customRender: 'flavor_name'
    },
  },
  {
    title: '尺寸',
    dataIndex: 'specification_name',
    align: 'center',
    slots: {
      customRender: 'specification_name'
    },
  },
  {
    title: 'SKU码',
    dataIndex: 'sku_number',
    align: 'center',
    slots: {
      customRender: 'sku_number'
    },
  },
  {
    title: '食用人数',
    dataIndex: 'diners_number_config',
    align: 'center',
    width: 150,
    slots: {
      customRender: 'diners_number_config'
    }
  },
  {
    title: '默认价',
    dataIndex: 'price',
    align: 'center',
    width:100,
    slots: {
      customRender: 'price'
    },
  },
  // {
  //   title: '区域价',
  //   dataIndex: 'area_price',
  //   align: 'center',
  //   width: 70,
  //   slots: {
  //     customRender: 'area_price'
  //   }
  // },
  // {
  //   title: '默认制作时间(h)',
  //   dataIndex: 'production_time',
  //   align: 'center',
  //   slots: {
  //     customRender: 'production_time'
  //   }
  // },
  {
    title: '默认制作时间(h)',
    dataIndex: 'production_hour',
    align: 'center',
    slots: {
      customRender: 'production_hour'
    }
  },
  {
    title: '城市制作时间',
    dataIndex: 'production_time_city',
    align: 'center',
    width:70,
    slots: {
      customRender: 'production_time_city'
    }
  },
  {
    title: 'SKU图片\n724*340',
    dataIndex: 'horizontal_image_path',
    align: 'center',
    width: 230,
    slots: {
      customRender: 'horizontal_image_path'
    }
  },
  {
    title: '售卖',
    dataIndex: 'status',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'status'
    }
  }]

export const goodsType = {
  // 1: '软点',
  // 2: '西点',
  3: '蛋糕'
}

export function formatType (val) {
  return goodsType[val]
}

export default {
  rowKey,
  columns,
  goodsType
}
