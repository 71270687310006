import ajax from '@/utils/ajax.js'


/////////////////////     门店管理     ////////////////////
/**
 * 获取门店分类列表
 * @param {*} params 
 * @returns 
 */
export function getMallTypeList(params) {
  return ajax.post('/XcxMallPageGoodsType/getList',params)
}


/**
 * 修改门店分类
 * @param {*} params 
 * @returns 
 */
 export function editMallType(params) {
  return ajax.post('/XcxMallPageGoodsType/edit',params)
}


/**
 * 获取门店商品列表
 * @param {*} params 
 * @returns 
 */
 export function getMallGoodsList(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/getList',params)
}


/**
 * 修改门店分类商品
 * @param {*} params 
 * @returns 
 */
 export function editMallTypeGoods(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/editTypeGoods',params)
}



/**
 * 获取商品价格图片信息
 * @param {*} params 
 * @returns 
 */
 export function getGoodsPriceList(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/getListAddUse',params)
}
